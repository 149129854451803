import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutComponent } from '../checkout/checkout.component';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-addres',
  templateUrl: './addres.component.html',
  styleUrls: ['./addres.component.scss'],
})
export class AddresComponent implements OnInit {
  clientAddress: FormGroup | any;
  clientId: any;
  token: any;
  userAddresses: any;
  showAddingNewAddressForm: boolean = false;
  addressIdSelected: any;

  constructor(
    public dialogRef: MatDialogRef<CheckoutComponent>,
    private readonly rest: RestService
  ) {}

  ngOnInit() {
    this.clientId = localStorage.getItem('clientID');
    this.token = localStorage.getItem('token');

    this.getClientAddress();

    this.clientAddress = new FormGroup({
      address: new FormControl('', [Validators.required]),
      governorate: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      client_id: new FormControl(this.clientId),
      // phone_number: new FormControl('', [Validators.required]),
    });
  }

  getClientAddress() {
    this.rest.getUserAddress(this.token, this.clientId).subscribe((res) => {
      this.userAddresses = res;
    });
  }

  onNoClick(): void {
    // this.dialogRef.close(this.clientAddress.value);
    this.dialogRef.close(this.addressIdSelected);
  }

  selectAddress(addressId: any) {
    this.addressIdSelected = addressId;
  }

  addNewAddress() {
    this.showAddingNewAddressForm = true;
  }

  saveAddress() {
    this.showAddingNewAddressForm = false;
    this.rest
      .addNewAddress(this.clientAddress.value, this.token)
      .subscribe(() => {
        this.rest.successToast('Address added successfully');
      });

    setTimeout(() => {
      this.getClientAddress();
    }, 100);
  }
}
